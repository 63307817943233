import { mapActions } from 'vuex'
import { STATUS } from '@/utils/key-mapping'

export default {
  data() {
    return {
      programStatus: false,
      status: STATUS,
      onConfirmToggleProgramStatusOpen: false
    }
  },

  methods: {
    ...mapActions('program', ['getProgramList', 'updateProgram'])
  }
}
