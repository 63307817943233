<template>
  <div v-if="stakingInfo">
    <div class="d-flex mb-16">
      <h1 class="headline--md text-bold text-uppercase">Staking Program</h1>

      <div
        v-if="currentUser && $auth('Page', currentUser, $accessRule.ProgramUpdate).canView()"
        class="d-flex align-start form-switch-wrapper"
      >
        <p class="mr-4">Go live on app:</p>

        <label class="form-switch">
          <input type="checkbox" v-model="programStatus" @click.prevent="onConfirmToggleProgramStatusOpen = true" />
          <span></span>
        </label>
      </div>

      <!-- <router-link
        v-if="programId"
        class="btn btn--primary"
        :to="{ name: 'PlanNew', params: { programId: programId } }"
        tag="button">
        Add Plan
      </router-link> -->
    </div>

    <div>
      <img :src="stakingInfo.imageUrl" width="360" class="d-block" alt="staking program image" />
    </div>

    <div v-if="plans" class="table-wrapper table-wrapper--fluid mt-16">
      <table class="table table--draggable">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Plan</th>
            <th>Min. Amount</th>
            <th>Max. Amount</th>
            <th>Hold Period</th>
            <th></th>
          </tr>
        </thead>

        <draggable v-model="plans" tag="tbody" v-bind="dragOptions" handle=".drag-handler" @update="onDragUpdate()">
          <tr v-for="plan in plans" :key="plan.id" class="link--clone">
            <td></td>
            <td>
              <span class="drag-handler">
                <toko-burger-icon />
              </span>
            </td>
            <router-link :to="{ name: 'Plan', params: { programId: programId, id: plan.id } }" tag="td">
              <!-- TODO: implement status -->
              <!-- <span
                  v-if="activated(plan.status)"
                  class="tag tag--success">Active</span>
                <span
                  v-else
                  class="tag tag--gray">Deactive</span> -->
              <p>{{ plan.title }}</p>
            </router-link>
            <router-link :to="{ name: 'Plan', params: { programId: programId, id: plan.id } }" tag="td">
              {{ plan.minAmount | number_with_commas }}
            </router-link>
            <router-link :to="{ name: 'Plan', params: { programId: programId, id: plan.id } }" tag="td">
              {{ plan.maxAmount | number_with_commas | maxAmount }}
            </router-link>
            <router-link :to="{ name: 'Plan', params: { programId: programId, id: plan.id } }" tag="td">
              {{ plan.period | format_month_count_text }}
            </router-link>
            <td></td>
          </tr>
        </draggable>
      </table>
    </div>

    <confirm-dialog
      v-if="onConfirmToggleProgramStatusOpen"
      :open.sync="onConfirmToggleProgramStatusOpen"
      confirmButtonText="Save"
      confirmButtonClass="text-purple"
      @confirm="onUpdateProgram"
    >
      <p v-if="programStatus">
        Are you sure to disable this program ?<br />
        User will not be able to stake anymore.
      </p>
      <p v-else>Are you sure to enable this program ?</p>
    </confirm-dialog>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import updateProgram from './mixins/update-program'
import formatActivate from '@/mixins/format-activate'
import toggleLoading from '@/mixins/toggle-loading'
const TokoBurgerIcon = () => import('@/ui/toko-icons/TokoBurger')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: 'plans',
        disabled: false
      },
      plans: null
    }
  },

  mixins: [updateProgram, formatActivate, toggleLoading],

  components: {
    draggable,
    TokoBurgerIcon,
    ConfirmDialog
  },

  filters: {
    maxAmount(value) {
      const maxAmount = value ? value : 'Unlimited'
      return maxAmount
    }
  },

  computed: {
    ...mapGetters('staking', ['getStakingPlansOrdered']),

    ...mapState('staking', ['programId', 'stakingInfo']),

    planIdsOrdered() {
      return this.plans.map(plan => plan.id)
    }
  },

  methods: {
    ...mapActions('staking', ['getStakingPlans']),

    ...mapActions('program', ['updateProgram']),

    onDragUpdate() {
      this.$lStorage.set('stakingPlanIdsOrdered', this.planIdsOrdered)
    },

    getProgramDataUpdate() {
      return {
        id: this.stakingInfo.id,
        status: this.programStatus ? this.status.deactive : this.status.active
      }
    },

    onUpdateProgram() {
      this.toggleLoading()
      this.updateProgram(this.getProgramDataUpdate())
        .then(() => {
          this.programStatus = this.stakingInfo.status === this.status.active
        })
        .finally(() => {
          this.onConfirmToggleProgramStatusOpen = false
          this.toggleLoading()
        })
    }
  },

  async created() {
    try {
      await this.getStakingPlans()
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.plans = this.getStakingPlansOrdered
    this.programStatus = this.stakingInfo.status === this.status.active
    this.toggleLoading()
  }
}
</script>
