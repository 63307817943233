import { STATUS } from '@/utils/key-mapping'

export default {
  data() {
    return {
      status: STATUS
    }
  },

  methods: {
    activated(status) {
      return status === this.status.active
    }
  }
}
